import React, { useRef, useEffect } from "react";
import { Canvas, useFrame, useLoader, extend, useThree } from "@react-three/fiber";
import { /*OrbitControls,*/ Stars } from "@react-three/drei";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import * as THREE from "three";

import EarthDayMap from "../../assets/textures/map_collage.jpg";
//import EarthNormalMap from "../../assets/textures/8k_earth_normal_map.jpg";
//import EarthSpecularMap from "../../assets/textures/8k_earth_specular_map.jpg";
//import LayoverMap from "../../assets/textures/world-layover.png";
import { TextureLoader} from "three";

extend({ OrbitControls });


export function Earth(props) {
  const [colorMap/*, normalMap, specularMap, VisitedMap*/] = useLoader(
    TextureLoader,
    [EarthDayMap/*, EarthNormalMap, EarthSpecularMap, LayoverMap*/]
  );

  const CameraControls = () => {
    const { camera, gl } = useThree();
    useEffect(
       () => {
          const controls = new OrbitControls(camera, gl.domElement);
          controls.minDistance = 1.6;
          controls.maxDistance = 10;
          controls.enableZoom=true;
          controls.enablePan=false;
          controls.enableRotate=true;
          controls.zoomSpeed=2;
          controls.panSpeed=0;
          controls.rotateSpeed=.5;
          controls.enableDamping=false;
          return () => {
            controls.dispose();
          };
       },
       [camera, gl]
    );
    return null;
 };


  const earthRef = useRef();
  //const cloudsRef = useRef();

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    earthRef.current.rotation.y = (elapsedTime / 48) + 180;
    //cloudsRef.current.rotation.y = (elapsedTime / 48) + 180;
  });

  return (
    <> 
      <ambientLight intensity={1.5} /> 
      <Stars
        radius={300}
        depth={60}
        count={20000}
        factor={7}
        saturation={0}
        fade={true}
      />
      {/*
      <mesh ref={cloudsRef}>
        <sphereGeometry args={[1.0001, 32, 32]} />
        <meshPhongMaterial
          map={VisitedMap}
          opacity={1}
          depthWrite={true}
          transparent={true}
          side={THREE.DoubleSide}
        />
        </mesh>
      */}
      <mesh ref={earthRef} /*position={[0, 0, 3]}*/>
        <sphereGeometry args={[1.5, 64, 64]} />
        {/*<meshPhongMaterial specularMap={specularMap} />*/}
        <meshStandardMaterial
          map={colorMap}
          /*normalMap={normalMap}*/
          metalness={0.5}
          roughness={0.7}
        />
        <CameraControls/>
      </mesh>
    </>
  );
}
